import moment from 'moment';

export const DATE_FORMAT = 'MM-DD-YYYY';

// TODO: Migrate these date formats to a shared component

export const DATE_PRIMARY_CHUNK = 'MMM. D';

export const DATE_PRIMARY = `${DATE_PRIMARY_CHUNK}, YYYY`;
export const DATE_SECONDARY = 'M/D/YYYY';

export const TIME_PRIMARY = 'h:mma';

export const DATE_TIME_PRIMARY = `${DATE_PRIMARY} ${TIME_PRIMARY}`;
export const DATE_TIME_SECONDARY = `${DATE_SECONDARY} ${TIME_PRIMARY}`;

export const DAY_DATE_PRIMARY = `dddd, ${DATE_PRIMARY}`;
export const DAY_DATE_SECONDARY = `dddd, ${DATE_SECONDARY}`;

export const DAY_DATE_TIME_PRIMARY = `${DAY_DATE_PRIMARY} ${TIME_PRIMARY}`;
export const DAY_DATE_TIME_SECONDARY = `${DAY_DATE_SECONDARY} ${TIME_PRIMARY}`;

export const DATE_TIME_COPY_COURSE = 'YYYY-MM-DDTHH:mm:ss';

export const DATE_TIME_ASSESSMENT_PERFORMANCE = 'MMM. D, YYYY [at] h:mm A z';

export const INVALID_DATE = moment.invalid().toString();

export const MIN_YEAR = 1900;
