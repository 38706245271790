export interface ELSTokenUser {
  appParams: {
    isbns: string;
    appId: string;
    courseId: number;
    authorizedIsbns: string;
  };
  emailAddress: string;
  expireDate: number;
  externalId: string;
  firstName: string;
  lastName: string;
  role: string;
  roles: string[];
  sessionId: string;
  userId: number;
  username: string;
}

export interface ELSCourseSectionDto {
  id: number;
  externalId: string;
  lmsClientId: string;
  consumerKey: string;
  lmsExternalId: string;
  courseName: string;
  sectionName?: string;
  duration?: string | number; // Unsure of this one
  institution: {
    id: number;
    name: string;
    externalId: string;
  };
  courseActive: boolean;
  entitlements: {
    id: number;
    isbn: string;
    type: string;
    data: string;
    parentIsbn?: string;
    evolveProductTypeKey?: string;
    appId?: string;
  }[];
  role: string;
  locked?: boolean;
  copied?: boolean;
  migrated: boolean;
  gradeSyncVersion: number;
  value: number;
  createdAt: number;
}

export interface ELSCourseSectionLiteDto {
  consumerKey: string;
  courseId: string;
  courseName: string;
  externalId: string;
  gradeSyncVersion: number;
  id: number;
  lmsClientId: string;
  lmsExternalId: string;
}

export enum migrationStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  INVALID_COURSE = 'INVALID_COURSE'
}

export interface ELSCourseMigrationSectionDto {
  courseSectionId: number;
  courseMigrationStatus: migrationStatus;
  entitlementIds: number[];
  previousAppId: string;
  updatedAppId: string;
}
